import { template as template_5c87cdcab1264c77a4f427cd101f2b3e } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_5c87cdcab1264c77a4f427cd101f2b3e(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
